import styled from 'styled-components'

export const PageContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;

  img {
    margin: 20px 0;
    width: 95%;
  }

  @media (min-width: 765px) {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    justify-content: space-between;
    img {
      width: 450px;
      height: auto;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 765px) {
    align-items: flex-start;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 765px) {
    align-items: flex-start;
  }
`

export const ContentText = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.4;
  p {
    margin-bottom: 10px;
  }
  @media (min-width: 765px) {
    width: 80%;
    font-size: 18px;
  }
`
