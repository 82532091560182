import React from 'react'
import { Container } from './TitleBox.styles'

interface Props {
  title: string
  bg: string
}
const TitleBox = ({ title, bg }: Props) => {
  return (
    <Container bg={bg}>
      <span>{title}</span>
    </Container>
  )
}

export default TitleBox
