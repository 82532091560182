import styled from 'styled-components'
import { MdExpandMore } from 'react-icons/md'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h2 {
    color: ${props => props.theme.colors.black};
  }
`

export const DownIcon = styled(MdExpandMore)`
  font-size: 48px;
`

export const Text = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.4;
  padding: 30px;
  p {
    margin-bottom: 10px;
  }
  @media (min-width: 765px) {
    width: 80%;
    font-size: 18px;
  }
`
