import React, { useState } from 'react'

import { Container, Text, TitleRow, DownIcon } from './Topic.styles'

interface Props {
  title: string
  text: string
}

const Topic = ({ title, text }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <TitleRow onClick={() => setIsOpen(!isOpen)}>
        <h2>{title}</h2>
        <DownIcon />
      </TitleRow>

      {isOpen && <Text dangerouslySetInnerHTML={{ __html: text }} />}
    </Container>
  )
}

export default Topic
