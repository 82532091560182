import React from 'react'

import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'
import Topic from 'components/Topic'

import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from 'pageStyles/sobre-nos.styles'

import veterinarian from 'images/veterinarian.jpg'

const topics = [
  {
    title: 'Odontologia',
    text: `
    <p>
      Você sabia que 85% dos cães em fase adulta apresentam algum tipo de problema dentário? E mais, esses problemas podem levar a doenças graves em outros órgãos?
      Assim como nós, os cães precisam de atenção especial em seus dentes e gengiva. É a partir da troca de dentição, por volta dos 5 meses, que os problemas se iniciam.
      Diferente dos humanos, geralmente um cão ou gato não reclama de dor de dente, com exceção de algum incomodo na hora da alimentação, sendo difícil reconhecer algum problema em saúde oral de forma precoce. Geralmente, descobrimos quando já estão de forma avançada. Portanto, uma inspeção regular da cavidade oral deve ser realizada em busca de problemas. Um sinal muito comum e percebido facilmente é o mau hálito.
      Outros sinais que podem ser percebidos são:
    </p>

    <ul> 
      <li>Gengivas muito vermelhas!</li>
      <li>Dificuldade em mastigar a ração!</li>
      <li>Presença de sangue, tanto na saliva como em brinquedos!</li>
      <li>Mastigação somente com um lado da boca!</li>
    </ul>
    
    <p>
      Em casos mais severos podemos ter secreções logo abaixo do olho, indicando fistula dentaria e secreções nasais indicando fistulas oronasais.
      O acúmulo de placa bacteriana, conhecido como tártaro, é uma das afecções orais que merecem destaque. Ela acontece pelo acúmulo de restos alimentares e consequente colonização de bactérias. As bactérias, em algum momento devido ao tempo de permanência, podem se deslocar para a corrente sanguínea e provocar lesões em outros órgãos, como os rins, causando doenças graves e algumas vezes incuráveis.
      Portanto, cuidar da saúde oral do seu PET é uma condição essencial para que ele tenha uma vida saudável e feliz!
      Algumas dicas de prevenção de tártaro:
    </p>

    <ul>
      <li>Escovação diária com pasta de dente e escova própria para cães!</li>
      <li>Palitos que previnem a formação de tártaro pelo atrito nos dentes!</li>
      <li>Ração de ótima qualidade!</li>
      <li>Avaliações frequentes da cavidade oral pelo médico veterinário é essencial para manter-se livre de sérios problemas!</li>
    </ul>

    <p>
      A clínica veterinária Derosso se destaca nos cuidados da saúde bucal, e possui em o serviço de odontologia, com equipamentos de ultima geração e equipe preparada para a avaliação e realização de procedimentos odontológicos.
      Traga seu Pet para uma avaliação!!!
    </p>
    `,
  },
  {
    title: 'Cardiologia',
    text: `
      <p>
        Coração saudável, sinal de boa saúde!
      </p>
      <p>
      A cardiologia veterinária é uma das especialidades veterinárias que vem crescendo bastante nos últimos anos. Focada no sistema cardiorrespiratório, ela é responsável pelo diagnóstico e tratamento das principais doenças do coração dos cães e dos gatos. Contanto com uma associação de tecnologia de ponta e profissionais altamente qualificados, o atendimento cardiológico passa por diagnósticos complexos para a avaliação de ritmo e função cardíacos e escolha de protocolos terapêuticos especifico para cada paciente. Para o correto diagnóstico, equipamentos de alta tecnologia são usados, permitindo que os profissionais capacitados do segmento tenham acesso à resultados cada vez mais claros, objetivos e que permitam a definição de diagnósticos e a indicação de tratamentos eficientes. Dentre eles, se destacam a eletrocardiografia e a ecocardiografia.
      </p>
      <p>Eletrocardiograma :</p>
      <p>Simples e relativamente rápido, o ECG é realizado de maneira não invasiva, onde eletrodos são colocados no corpo do paciente, modo muito similar de realização aos exames de humanos. O exame possibilita identificar diversas alterações que possa haver no coração do paciente por indicar a existência de alterações da frequência elétrica no coração. Indicado para doenças do coração que incluem arritmias, desmaios e também em avaliações pré-operatórias levando grande segurança ao procedimento anestésico.
      </p>
      <p>
      Ecocardiograma :
      </p>
      <p>
      O ecocardiograma consiste em uma ultrassonografia do coração, com a vantagem também de não ser invasivo ou estressante para o paciente. A ecocardiografia possibilita a medição do tamanho do órgão, a averiguação de seus movimentos e o aparecimento de massas e líquidos ao seu redor. É indicado para pacientes com suspeita de doença cardíaca, como sopros e avaliações preventivas de animais idosos.</p>
      <p>Portanto, fique muito atento a sinais comuns de doenças do coração como:</p>
      <ul>
        <li>Desmaios;
        </li>
        <li>Tosse frequente;</li>
        <li>Dificuldade de respirar</li>
      </ul>
      <p>Nestes sinais, é muito importante a avaliação por um médico veterinário especializado, que você encontra no corpo clínico da Clínica Veterinária Derosso!</p>
      `,
  },
  {
    title: 'Oftamologia',
    text: `
      <p>Dentre as especialidades da Clínica Veterinária Derosso está a Oftalmologia, uma das áreas da veterinária com grande rotina nos dias atuais. Responsável pelo diagnóstico e tratamento de doenças relacionadas ao globo ocular e seus anexos (pálpebras e cílios, entre outros), a especialidade requer profissionais altamente qualificados e tecnologia de ponta. Hoje, é muito comum cães e gatos de raças que apresentam deformações anatômicas nas pálpebras e cílios que acabam por machucar o olho, levando a lesões na córnea, e com necessidade de procedimentos de correção, já quando filhotes. Outras situações incluem traumas no olho, causando úlceras necessitando de tratamentos prolongados com colírios e até procedimentos cirúrgicos. Doenças comuns aos pacientes idosos, como a catarata, já possuem tratamento efetivo e com ótimos resultados.</p>
      <ul>
      <li>Olhos opacos</li>
      <li>Dificuldade em abrir e fechar os olhos</li>
      <li>Piscar excessivamente</li>
      <li>Olhos avermelhados</li>
      </ul>
    `,
  },
  {
    title: 'Ortopedia',
    text: `
      <p>Especialidade presente na clínica veterinária Derosso, a ortopedia é a área da medicina veterinária responsável pelo diagnóstico e tratamento dos animais com as mais variadas complicações nos ossos, músculos, ligamentos e articulações. Essas complicações vão das mais comuns, como as fraturas por traumas de quedas e atropelamentos, até lesões especificas de algumas raças.</p>
      <p>A Clínica Veterinária Derosso conta com profissional capacitado para o tratamento clínico e cirúrgico das lesões ortopédicas e de todo o aparato diagnóstico de alta tecnologia, como a radiografia digital, ultrassonografia e laboratório.</p>
    `,
  },
]

const Especialidades = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Especialidades' bg={veterinarian} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <ContentText>
                <p>
                  O Setor de Diagnóstico por Imagem da Clínica Veterinária
                  Derosso conta com equipamentos modernos que produzem imagens
                  de alta qualidade e conta profissionais especializados para
                  perfeita realização de exames e laudos precisos que auxiliam
                  os veterinários a ter o melhor diagnóstico, o que é essencial
                  para o tratamento mais adequado do seu animal.
                </p>

                <ul>
                  <li>
                    <p>
                      O serviço de radiologia fornecem imagens dos ossos e
                      certos órgãos e tecidos de forma não invasiva, e com nosso
                      aparelho de Raio X Digital cuja imagens facilitam a
                      visualização e interpretação do Médico Veterinário a
                      conseguir um diagnóstico preciso e mais agilidade na
                      realização do exame
                    </p>
                  </li>
                  <li>
                    <p>
                      O exame de ultrassom produz imagens em tempo real das
                      partes internas do seu animal que não são possíveis de ser
                      percebidas no exame clínico. Nosso aparelho de ecografia é
                      de última geração produzindo imagens de alta definição que
                      além da funcionalidade de exploração, é capaz de realizar
                      biópsias, acompanhamento gestacional e coletas de
                      liquidos, como urina, trazendo grande segurança ao
                      procedimento.
                    </p>
                  </li>
                </ul>
                <p>
                  A busca pela evolução de nossos equipamentos e treinamento de
                  nossa equipe, nos diferencia em nossa região, atendendo não
                  somente casos da propria clínica, mas também de colegas que
                  nos referenciam.
                </p>
              </ContentText>
            </ContentWrapper>
          </Fade>
          <Fade>
            <>
              {topics.map((topic, i) => (
                <Topic title={topic.title} text={topic.text} key={i} />
              ))}
            </>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Especialidades
