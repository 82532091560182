import styled from 'styled-components'

export const Container = styled.div<ContainerProps>`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.grey};
  background-image: ${props => `url(${props.bg})`};
  background-size: cover;
  background-position: center center;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #0093d8, #00d8b1);
    opacity: 0.8;
  }

  span {
    color: ${props => props.theme.colors.white};
    font-size: 22px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    position: relative;
    z-index: 2;
  }

  @media (min-width: 765px) {
    height: 220px;
    span {
      font-size: 26px;
    }
  }
`

interface ContainerProps {
  bg: string
}
